<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div class="text-right">
      <ModalEdit />
    </div>
    <v-divider></v-divider>
    <v-card outlined height="572" class="mt-2">
      <iframe
        :src="getPDF(item.profil_file)"
        type="application/pdf"
        height="100%"
        width="100%"
      ></iframe>
    </v-card>
  </div>
</template>

<script>
import ModalEdit from "@/components/Admin/profil/tupoksi/modalEdit";
import refreshView from "@/store/profil/tupoksi/viewTupoksi";
export default {
  components: {
    ModalEdit,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    session: "",

    item: {},
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getPDF(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/profilGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE + "upload/profilGambar/pdf-default.pdf"
        );
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/tupoksi")
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
