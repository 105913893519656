<template>
  <v-dialog v-model="ModalEdit" :max-width="dekstop">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            color="primary"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title >Edit Tupoksi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <v-row>
            <v-col cols="8">
              <v-card outlined height="572" class="mt-2">
                <iframe
                  :src="defaultPDF"
                  type="application/pdf"
                  height="100%"
                  width="100%"
                ></iframe>
              </v-card>
            </v-col>

            <v-col cols="4">
              <span class="subtitle-2">File Tupoksi</span>
              <v-file-input
                dense
                flat
                outlined
                prepend-icon
                accept=".pdf"
                placeholder="Pilih File"
                append-icon="mdi-file-pdf-box"
                @change="onFile"
              ></v-file-input>

              <hr />
              <div class="text-right mt-5 pb-5">
                <v-btn
                  v-if="btnLoading"
                  small
                  color="primary"
                  depressed
                  @click="edit()"
                  >SIMPAN</v-btn
                >
                <v-btn v-else small color="primary" depressed loading
                  >SIMPAN</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/profil/tupoksi/viewTupoksi";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "80%",
    ModalEdit: false,
    btnLoading: true,

    defaultPDF: "",
  }),

  methods: {
    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.getData();
      this.ModalEdit = true;
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/tupoksi")
        .then((res) => {
          this.item = res.data.data;
          this.defaultPDF =
            res.data.data.profil_file === ""
              ? process.env.VUE_APP_API_BASE +
                "upload/profilGambar/pdf-default.pdf"
              : process.env.VUE_APP_API_BASE +
                "upload/profilGambar/" +
                res.data.data.profil_file;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeModal() {
      this.ModalEdit = false;
      this.urlImage = "";
    },

    async edit() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("profil_file", this.profil_file);

      const url = process.env.VUE_APP_API_BASE + "profil/tupoksi";
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.profil_file = value;
      this.defaultPDF = URL.createObjectURL(this.profil_file);
    },
  },
};
</script>
