<template>
  <div>
    <v-alert>
      <h3>Tupoksi Inspektorat</h3>
    </v-alert>

    <div class="mb-5">
      <CTupoksi />
    </div>
  </div>
</template>

<script>
import CTupoksi from "@/components/Admin/profil/tupoksi/tupoksi";

export default {
  components: {
    CTupoksi,
  },

  data: () => ({
    session: "",
  }),
};
</script>
